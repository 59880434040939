import { FirebaseError } from '@firebase/util';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { Button } from '../../components/Button';
import { useFirebaseAuth, useFirebaseAuthState } from '../../components/FirebaseProvider';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { TextLink } from '../../components/TextLink';
import { TextField } from '../../components/forms/Field';

export default function LoginPage() {
  const { signInWithEmailAndPassword, signInWithGoogle, signInWithApple } = useFirebaseAuth();
  const { loggedIn, loading } = useFirebaseAuthState();
  const router = useRouter();
  const [error, setError] = useState<FirebaseError>();
  const returnTo = typeof router.query['return-to'] === 'string' ? router.query['return-to'] : '/my-choosy';

  useEffect(() => {
    if (loading) {
      return;
    }

    if (loggedIn) {
      router.replace(returnTo);
    }
  }, [loading, loggedIn, returnTo, router]);

  async function handleSignInWithEmail({ email, password }: LoginFormData) {
    try {
      setError(undefined);
      await signInWithEmailAndPassword(email, password);
    } catch (err) {
      if (!(err instanceof FirebaseError)) {
        throw err;
      }
      setError(err);
    }
  }

  async function handleSignInWithGoogle() {
    try {
      setError(undefined);
      await signInWithGoogle();
    } catch (err) {
      if (!(err instanceof FirebaseError)) {
        throw err;
      }
      setError(err);
    }
  }

  async function handleSignInWithApple() {
    try {
      setError(undefined);
      await signInWithApple();
    } catch (err) {
      if (!(err instanceof FirebaseError)) {
        throw err;
      }
      console.error(err);
      setError(err);
    }
  }

  if (loading || loggedIn) {
    return null;
  }

  return (
    <>
      <Head>
        <title>Choosy – Login</title>
      </Head>
      <Header />
      <main className="flex-grow px-6 md:px-10 py-20 md:py-32">
        <section className="flex flex-col items-center space-y-12 mx-auto w-full max-w-114">
          {error ? <LoginError error={error} /> : null}
          <div className="w-full p-6 md:p-12 border border-gray-100 shadow-lg">
            <div className="flex justify-end">
              <TextLink
                href={returnTo ? `/login/signup?return-to=${encodeURIComponent(returnTo)}` : '/login/signup'}
                className="ml-2 font-sm"
              >
                Registrieren
              </TextLink>
            </div>
            <LoginForm className="mt-8" onSubmit={handleSignInWithEmail} />
            <SocialLogin
              className="mt-12 border-t pt-10 border-gray-200"
              onSignInWithGoogle={handleSignInWithGoogle}
              onSignInWithApple={handleSignInWithApple}
            />
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

type LoginFormData = {
  readonly email: string;
  readonly password: string;
};

type LoginFormProps = {
  readonly onSubmit: (data: LoginFormData) => Promise<unknown>;
  readonly className?: string;
};

function LoginForm({ onSubmit, className }: LoginFormProps) {
  const { register, handleSubmit } = useForm<LoginFormData>({});

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cn('w-full flex flex-col items-stretch', className)}>
      <TextField
        {...register('email', { required: true })}
        id="email"
        className="w-full"
        type="email"
        placeholder="E-Mail"
      />
      <div className="mt-8">
        <TextField
          {...register('password', { required: true })}
          id="password"
          className="w-full"
          type="password"
          placeholder="Passwort"
        />
      </div>
      <div className="mt-2 flex justify-end">
        <TextLink href="/login/password-forgotten" className="font-sm">
          Passwort vergessen?
        </TextLink>
      </div>
      <div className="mt-8 flex items-center">
        <Button type="submit" size="md" className="w-full">
          Anmelden
        </Button>
      </div>
    </form>
  );
}

type SocialLoginProps = {
  readonly className?: string;
  readonly onSignInWithGoogle: () => unknown;
  readonly onSignInWithApple: () => unknown;
};

function SocialLogin({ className, onSignInWithGoogle, onSignInWithApple }: SocialLoginProps) {
  return (
    <div className={cn('flex flex-col w-full', className)}>
      <h3 className="text-4.5 text-center">Anmelden mit</h3>
      <div className="mt-8 flex flex-col sm:flex-row sm:justify-between">
        <Button size="md" theme="google" onClick={onSignInWithGoogle}>
          <img src="/images/google-logo.svg" alt="" className="h-4 mr-2" />
          Google
        </Button>
        <Button size="md" theme="apple" onClick={onSignInWithApple}>
          <img src="/images/apple-logo-white.svg" alt="" className="h-4 mr-2" />
          Apple
        </Button>
      </div>
    </div>
  );
}

type LoginErrorProps = {
  readonly error: FirebaseError;
};

function LoginError({}: LoginErrorProps) {
  return <div>Login fehlgeschlagen, kontrolliere deine E-Mail und dein Passwort und versuche es erneut</div>;
}
